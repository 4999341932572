import { useSearchParams } from "react-router-dom";
import {
  useGetQuestions,
} from "../../api/questions.api";
import BaseQuestionsEdit from "../../components/miscellaneous/QuestionsEdit/BaseQuestionsEdit";


const QuestionsEdit = () => {
  const [searchParams] = useSearchParams();

  const cryptorId = Number(searchParams.get("cryptorId"));

  const { data } = useGetQuestions(cryptorId);

  if (!data) return null;

  return (
    <BaseQuestionsEdit data={data} cryptorId={cryptorId} />
  )
}

export default QuestionsEdit;