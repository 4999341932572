import { z } from "zod";

export const QuestionsSchema = z.object({
    reward: z.coerce.number().optional(),
    timerQuestion: z.coerce.number().optional(),
    questions: z.array(
        z.object({
            id: z.string().nullable(),
            textTranslation: z.object({ value: z.string().min(1).optional() }),
            reward: z.coerce.number().min(0),
            index: z.coerce.number().min(0),
            cryptorId: z.number().nullable(),
            quizId: z.number().nullable(),
            propositions: z.array(
                z.object({
                    id: z.string().optional(),
                    textTranslation: z.object({ value: z.string() }),
                    isCorrect: z.boolean(),
                })
            ),
            answerDetails: z
                .object({
                    id: z.number().optional(),
                    descriptionTranslation: z.object({ value: z.string() }),
                    image: z.object({ url: z.string().min(1).nullable() }).nullable(),
                })
                .nullable(),
        })
    ),
});

export type QuestionsFormData = z.infer<typeof QuestionsSchema>;