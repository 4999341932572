import React, { useMemo } from "react";
import {
  useDataGrid,
  EditButton,
  ShowButton,
  DeleteButton,
  List,
  CreateButton,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps } from "@refinedev/core";
import { useParams } from "react-router-dom";
import { ChatMessage } from "../../../../backend/shared/types/chat/chat.type";
import { getTranslation } from "../../utils/translation";

export const ChatMessageList: React.FC<IResourceComponentsProps> = () => {
  const { cryptorId } = useParams();

  const {
    dataGridProps: { rows, ...rest },
  } = useDataGrid({
    resource: `chat/cryptors/${cryptorId}/chat-message`,
    pagination: { mode: "client" },
    sorters: { mode: "off" },
    filters: { mode: "off" },
  });

  const formatedData = rows.map((chatMessage) => ({
    ...chatMessage,
    text: getTranslation(chatMessage.textTranslation)?.value,
  }));

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "id",
        headerName: "Id",
        type: "number",
        minWidth: 50,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "text",
        headerName: "Texte",
        flex: 1,
      },
      {
        field: "image",
        headerName: "Image",
        type: "boolean",
        width: 70,
      },
      {
        field: "video",
        headerName: "Video",
        type: "boolean",
        width: 70,
      },
      {
        field: "audio",
        headerName: "Son",
        type: "boolean",
        width: 70,
      },
      {
        field: "question",
        headerName: "Question",
        type: "boolean",
        width: 75,
      },
      {
        field: "isEntryPoint",
        headerName: "Point d'entrée",
        type: "boolean",
        minWidth: 105,
      },
      {
        field: "shouldTriggerQuiz",
        headerName: "Quiz",
        type: "boolean",
        minWidth: 105,
      },
      {
        field: "chatMessageChildrenId",
        headerName: "Enfants",
        type: "number",
        headerAlign: "center",
        align: "center",
        valueGetter: ({ row }: { row: ChatMessage }) => {
          return [
            row?.chatMessageChildrenId,
            row.question?.propositions.map((p) => p.chatMessageId),
          ]
            .flat()
            .filter((el) => el)
            .join(", ");
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText recordItemId={row.id} />
              <ShowButton hideText recordItemId={row.id} />
              <DeleteButton
                hideText
                recordItemId={row.id}
                resource={`chat/cryptors/${cryptorId}/chat-message`}
                successNotification={{
                  type: "success",
                  message: "Le message a bien été supprimé",
                }}
                errorNotification={{
                  type: "error",
                  message: "Une erreur est survenue",
                }}
              />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 125,
      },
    ],
    [cryptorId]
  );

  return (
    <List headerButtons={<CreateButton />}>
      <DataGrid {...rest} rows={formatedData} columns={columns} autoHeight />
    </List>
  );
};
