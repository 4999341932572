import { useShow, IResourceComponentsProps, useBack } from "@refinedev/core";
import {
  Show,
  NumberField,
  TextFieldComponent as TextField,
  BooleanField,
} from "@refinedev/mui";
import { Typography, Stack, Divider, IconButton, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { ChatMessage, ChatProposition } from "shared";
import { FC, useMemo } from "react";
import { ArrowBack, Close, Done } from "@mui/icons-material";
import AudioPlayer from "../../components/miscellaneous/AudioPlayer";
import ChatMessageChildrenShow from "./components/ChatMessageChildrenShow";
import { getTranslation } from "../../utils/translation";
import SelectLanguage from "../../components/miscellaneous/SelectLanguage";
import i18n from "../../i18n";

interface ChatMessageShowProps extends IResourceComponentsProps {
  idProps?: number;
}

export const ChatMessageShow: React.FC<ChatMessageShowProps> = ({
  idProps,
}) => {
  const { cryptorId, id: idURL } = useParams();
  const back = useBack();

  const id = useMemo(() => idProps || idURL, [idURL, idProps]);

  const { queryResult } = useShow({
    resource: `chat/cryptors/${cryptorId}/chat-message`,
    id,
  });
  const { data, isLoading } = queryResult;

  const record = data?.data as ChatMessage | undefined;

  const youtubeEmbededUrl = useMemo(() => {
    if (record?.video?.url) {
      const videoId = new URL(record.video.url).searchParams.get("v");
      return `https://www.youtube.com/embed/${videoId}`;
    }
  }, [record?.video?.url]);

  return (
    <Show
      isLoading={isLoading}
      goBack={
        <IconButton onClick={back}>
          <ArrowBack />
        </IconButton>
      }
      headerButtons={({ defaultButtons }) => (
        <Box display="flex" alignItems="center" gap={1}>
          {defaultButtons}
          <SelectLanguage />
        </Box>
      )}
    >
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          Id
        </Typography>
        <NumberField value={record?.id ?? ""} />
        <Typography variant="body1" fontWeight="bold">
          Est le point d'entrée
        </Typography>
        <BooleanField value={record?.isEntryPoint} />
        <Typography variant="body1" fontWeight="bold">
          Déclenchement du quiz
        </Typography>
        <BooleanField value={record?.shouldTriggerQuiz} />
        <Typography variant="body1" fontWeight="bold">
          Texte
        </Typography>
        <TextField
          value={getTranslation(record?.textTranslation, i18n.language)?.value}
        />
        <Typography variant="body1" fontWeight="bold">
          Image
        </Typography>
        {record?.image?.url && (
          <img src={record.image.url} alt="" width="100" />
        )}
        <Typography variant="body1" fontWeight="bold">
          Vidéo
        </Typography>
        {youtubeEmbededUrl && (
          <iframe
            width="560"
            height="315"
            src={youtubeEmbededUrl}
            title="YouTube video player"
          />
        )}
        <TextField value={record?.video?.url} />
        <Typography variant="body1" fontWeight="bold">
          Son
        </Typography>
        {record?.audio?.url && <AudioPlayer url={record.audio.url} />}
        {record?.chatMessageChildrenId && (
          <ChatMessageChildrenShow
            chatMessageChildrenId={record.chatMessageChildrenId}
          />
        )}
        {record?.question && (
          <>
            <Divider>Question</Divider>
            {record.question?.type && (
              <>
                <Typography variant="body1" fontWeight="bold">
                  Type
                </Typography>
                <TextField
                  value={
                    record.question.type === "CHOICE" ? "Choix" : "Question"
                  }
                />
              </>
            )}
            {record.question?.reward !== undefined && (
              <>
                <Typography variant="body1" fontWeight="bold">
                  Récompense
                </Typography>
                <TextField value={record.question.reward} />
              </>
            )}
            {record.question?.textTranslation && (
              <>
                <Typography variant="body1" fontWeight="bold">
                  Question
                </Typography>
                <TextField
                  value={
                    getTranslation(record.question?.textTranslation)?.value
                  }
                />
                {record.question.propositions?.map((proposition) => (
                  <Question key={proposition.id} proposition={proposition} />
                ))}
              </>
            )}
          </>
        )}
      </Stack>
    </Show>
  );
};

interface IQuestionProps {
  proposition: ChatProposition;
}

const Question: FC<IQuestionProps> = ({ proposition }) => {
  return (
    <Stack direction="row" alignItems="center">
      {proposition.isCorrect ? <Done /> : <Close />}
      <TextField
        value={getTranslation(proposition.textTranslation)?.value}
        sx={{ marginRight: 2 }}
      />
      {proposition.chatMessageId && (
        <ChatMessageChildrenShow
          chatMessageChildrenId={proposition.chatMessageId}
          showLabel={false}
        />
      )}
    </Stack>
  );
};
